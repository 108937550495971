.content-login {
  display: flex;
  margin-top: 64px;
  align-items: center;
  flex-direction: column;
}

.avatar-login {
  margin: 8px;
  background-color: #f50057 !important;
}

.form-login {
  width: 100%;
  margin-top: 8px;
}

.button-login {
  margin: 24px 0px 16px !important;
}